import { useEffect } from 'react';
import { getSessionStorage, setSessionStorage } from '../../utilities/functions';
import { generateUTMParams } from '../../templates/rta/scripts';

export const SessionWrapper = ({ children }) => {
	useEffect(() => {
		if (getSessionStorage('activeSession')) return;

		const params = new URLSearchParams(window.location.search);
		let paramsObject = {};

		for (var value of params.keys()) {
			paramsObject[value] = params.get(value);
		}

		generateUTMParams(params);
		setSessionStorage('activeSession', 'true');
	}, []);

	return children;
};
