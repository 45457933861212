import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getShowroomByIP } from '../actions/location-actions';
import { useBranchData } from '../hooks/use-branch-data';
import { useShowroomUrls } from '../hooks/use-showroom-urls';
import { getLocalStorage, getSessionStorage, setLocalStorage, setSessionStorage } from '../components/utilities/functions';

export const BranchContext = createContext({});

export function BranchProvider(props) {
	const branches = useBranchData();
	const showroomUrls = useShowroomUrls();
	const [currentBranch, setCurrentBranch] = useState({});
	const [preferredShowroom, setPreferredShowroom] = useState({});
	const [showroomOverride, setShowroomOverride] = useState(null);

	useEffect(() => {
		let params = new URLSearchParams(window.location.search);
		let branchID = params.get('branch') || params.get('Branch') || window.location.search?.split('&')?.pop();

		// Check for branch utm to exist, if not do showroomOverride if it exists
		if (branchID != null && branches?.find((branch) => branch.utm_branch === branchID?.toLowerCase())?.formal_name) {
			const currentBranch = branches?.find((branch) => branch.utm_branch === branchID?.toLowerCase());
			setCurrentBranch(currentBranch);
			setSessionStorage('PBCurrent', { formal_name: currentBranch.formal_name, url: currentBranch.branch_url });
		} else if (showroomOverride?.formal_name) {
			setCurrentBranch(showroomOverride);
			setSessionStorage('PBCurrent', { formal_name: showroomOverride.formal_name, url: showroomOverride.branch_url });
			if (getLocalStorage('PBLocation')?.branch_id) return;
		}

		// Persist current branch through general pages
		if (getSessionStorage('PBCurrent')?.formal_name) {
			const currentBranch = branches?.find((branch) => branch.formal_name === getSessionStorage('PBCurrent').formal_name);
			setCurrentBranch(currentBranch);
		}

		if (getLocalStorage('PBLocation')?.branch_id) {
			setPreferredShowroom(getLocalStorage('PBLocation'));

			if (!getSessionStorage('PBCurrent')?.formal_name) {
				const branchID = getLocalStorage('PBLocation').branch_id;
				const currentBranch = branches?.find((branch) => branch.branch_retail?.concat(branch.branch_trade)?.includes(branchID));
				setCurrentBranch(currentBranch);
				setSessionStorage('PBCurrent', { formal_name: currentBranch?.formal_name, url: currentBranch?.branch_url });
			}
		} else {
			if (getSessionStorage('PBCurrent') != null || preferredShowroom?.store_number) return;

			const fetchShowroomByIP = async () => {
				const response = await getShowroomByIP();
				if (response) {
					const branch = branches?.find((branch) => branch.branch_retail?.concat(branch.branch_trade)?.includes(response.branch_id));

					const showroomUrl = showroomUrls?.find((showroomUrl) => showroomUrl.showroom_id === response.store_number);
					setPreferredShowroom({ ...response, showroom_formal_name: showroomUrl?.formal_name, url: showroomUrl?.url || branch?.branch_url });
					setLocalStorage('PBLocation', { ...response, showroom_formal_name: showroomUrl?.formal_name, url: showroomUrl?.url || branch?.branch_url });

					if (branch) {
						setCurrentBranch(branch);
						setSessionStorage('PBCurrent', { formal_name: branch.formal_name, url: branch.branch_url }); // use session storage because a lot relies on branch data which can change
					}
				}
			};

			fetchShowroomByIP();
		}
	}, [branches, showroomUrls, showroomOverride, preferredShowroom?.store_number]);

	useEffect(() => {
		window.dataLayer = window.dataLayer || [];
		const timeoutId = setTimeout(() => {
			if (window.dataLayer?.find((data) => data.event === 'branchIdentifier' && data.branch === currentBranch?.utm_branch)) return;
			window.dataLayer.push({ event: 'branchIdentifier', branch: currentBranch?.utm_branch });
		}, 100);
		return () => clearTimeout(timeoutId);
	}, [currentBranch]);

	return (
		<BranchContext.Provider
			value={{
				currentBranch,
				setCurrentBranch,
				preferredShowroom,
				setPreferredShowroom,
				showroomOverride,
				setShowroomOverride,
			}}
		>
			{props.children}
		</BranchContext.Provider>
	);
}

BranchProvider.propTypes = {
	children: PropTypes.node,
};
