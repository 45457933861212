import axios from 'axios';

const pellaAPIURL = process.env.GATSBY_PELLA_API;

export const getShowroomsByIP = async (coordinates = null) => {
	const params = {};
	if (coordinates) {
		params.lon = coordinates[0];
		params.lat = coordinates[1];
	}

	try {
		let config = {
			withCredentials: true,
			params,
		};

		const response = await axios.get(`${pellaAPIURL}/location/v2/showrooms`, config);
		return response.data;
	} catch (error) {
		if (error.response?.data) {
			return error.response.data;
		} else {
			return { success: false };
		}
	}
};

export const getShowrooms = async (postalCode) => {
	try {
		let config = {
			withCredentials: true,
		};
		const response = await axios.get(`${pellaAPIURL}/location/v2/showrooms/${postalCode}`, config);
		return response.data;
	} catch (error) {
		if (error.response?.data) {
			return error.response.data;
		} else {
			return { success: false };
		}
	}
};

export const getShowroomByIP = async (coordinates = null) => {
	const params = {};
	if (coordinates) {
		params.lon = coordinates[0];
		params.lat = coordinates[1];
	}

	try {
		let config = {
			withCredentials: true,
			params,
		};

		const response = await axios.get(`${pellaAPIURL}/location/v2/showroom`, config);
		return response.data;
	} catch (error) {
		if (error.response?.data) {
			return error.response.data;
		} else {
			return { success: false };
		}
	}
};

export const getShowroom = (postalCode) => {
	axios
		.get(`${pellaAPIURL}/location/v2/showroom/${postalCode}`)
		.then((res) => res.data)
		.catch((error) => ({ success: false, error: error }));
};

export const getShowroomByID = async (showroomID) => {
	try {
		const response = await axios.get(`${pellaAPIURL}/location/v2/showroom/${showroomID}`);
		return response.data;
	} catch (error) {
		return { success: false, error: error };
	}
};

export const getShowroomsByBranch = async (branch_id) => {
	try {
		let config = {
			withCredentials: true,
		};

		const response = await axios.get(`${pellaAPIURL}/location/v2/branch/${branch_id}/showrooms`, config);
		return response?.data;
	} catch (error) {
		if (error.response?.data) {
			return error.response.data;
		} else {
			return { success: false };
		}
	}
};
