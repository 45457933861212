import Cookies from 'universal-cookie';
import { CA_ZIP_REGEX, EMAIL_REGEX, PHONE_REPLACE, US_ZIP_REGEX } from './constants';

const cookies = new Cookies();

/**
 * Validate Postal Code
 * @param {string} postalCode
 * @returns Boolean based on whether the postal code is valid
 */
export const validatePostal = (postalCode = '') => {
	if (postalCode.match(US_ZIP_REGEX) === null && postalCode.match(CA_ZIP_REGEX) === null) return false;
	return true;
};

/**
 * Validate Email Address
 * @param {string} email
 * @returns Boolean based on whether the email is valid
 */
export const validateEmail = (email = '') => {
	if (email.match(EMAIL_REGEX) === null) return false;
	return true;
};

/**
 * Validate Phone Number
 * @param {string} phone
 * @returns Boolean based on whether the phone number is valid
 */
export const validatePhone = (phone = '') => {
	if (phone.replace(PHONE_REPLACE, '').length !== 10) return false;
	return true;
};

/**
 * Format Phone Number
 * @param {string} phone
 * @returns Formatted phone number as (###) ###-####
 */
export const formatPhoneNumber = (phone) => {
	const cleaned = ('' + phone).replace(/\D/g, '');
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		let international = match[1] ? '+1 ' : '';
		return [international, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}

	return null;
};

/**
 * Remove Phone Number Format
 * @param {string} phone
 * @returns Unformatted phone number
 */
export const removePhoneNumberFormat = (phone) => {
	if (!phone) return '';
	else return phone.replace(PHONE_REPLACE, '');
};

/**
 * Set Local Storage
 * @param {string} key
 * @param {any} value
 * @param {boolean} stringify
 * @returns boolean based on success
 */
export const setLocalStorage = (key, value, stringify = true) => {
	if (storageAvailable('localStorage')) {
		try {
			localStorage.setItem(key, stringify ? JSON.stringify(value) : value);
		} catch (error) {
			return false;
		}
	} else {
		return false;
	}
};

/**
 * Get Local Storage
 * @param {string} key
 * @returns value that was initially stored
 */
export const getLocalStorage = (key) => {
	if (storageAvailable('localStorage')) {
		try {
			let value = localStorage.getItem(key);

			if (typeof value === 'string' && ((value.startsWith('{') && value.endsWith('}')) || (value.startsWith('[') && value.endsWith(']')))) {
				try {
					value = JSON.parse(value);
				} catch {}
			}

			return value;
		} catch (error) {
			return null;
		}
	} else {
		return null;
	}
};

/**
 * Remove Local Storage
 * @param {string} key
 * @returns boolean based on success
 */
export const removeLocalStorage = (key) => {
	if (storageAvailable('localStorage')) {
		try {
			localStorage.removeItem(key);
		} catch (error) {
			return false;
		}
	} else {
		return false;
	}
};

/**
 * Get Session Storage
 * @param {string} key
 * @returns boolean based on success
 */
export const getSessionStorage = (key) => {
	if (storageAvailable('sessionStorage')) {
		try {
			let value = sessionStorage.getItem(key);

			if (typeof value === 'string' && ((value.startsWith('{') && value.endsWith('}')) || (value.startsWith('[') && value.endsWith(']')))) {
				try {
					value = JSON.parse(value);
				} catch {}
			}

			return value;
		} catch (error) {
			return null;
		}
	} else {
		return null;
	}
};

/**
 * Set Session Storage
 * @param {string} key
 * @param {any} value
 * @param {boolean} stringify
 * @returns boolean based on success of storing
 */
export const setSessionStorage = (key, value, stringify = true) => {
	if (storageAvailable('sessionStorage')) {
		try {
			sessionStorage.setItem(key, stringify ? JSON.stringify(value) : value);
		} catch (error) {
			return false;
		}
	} else {
		return false;
	}
};

/**
 * Remove Session Storage
 * @param {string} key
 * @returns boolean based on success of removal
 */
export const removeSessionStorage = (key) => {
	if (storageAvailable('sessionStorage')) {
		try {
			return sessionStorage.removeItem(key);
		} catch (error) {
			return null;
		}
	} else {
		return null;
	}
};

/**
 * Storage Available
 * @param {string} type
 * @returns boolean based on storage availability
 */
export const storageAvailable = (type) => {
	// accepts sessionstorage or localstorage
	// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage

	if (typeof window !== 'undefined') {
		var storage;
		try {
			storage = window[type];
			var x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		} catch (e) {
			return (
				e instanceof DOMException &&
				// everything except Firefox
				(e.code === 22 ||
					// Firefox
					e.code === 1014 ||
					// test name field too, because code might not be present
					// everything except Firefox
					e.name === 'QuotaExceededError' ||
					// Firefox
					e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
				// acknowledge QuotaExceededError only if there's something already stored
				storage &&
				storage.length !== 0
			);
		}
	}
};

/**
 * Randomize Array - randomize the order of an array
 * @param {array} array
 * @returns re-ordered array
 */
export const randomizeArray = (array) => {
	let currentIndex = array.length,
		randomIndex;

	while (currentIndex > 0) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}

	return array;
};

/**
 * Country From Postal
 * @param {string} postalCode
 * @returns USA or CAN
 */
export const getCountryFromPostal = (postalCode = '') => {
	if (postalCode.match(CA_ZIP_REGEX) !== null) {
		return 'CAN';
	}
	return 'USA';
};

/**
 * Set Cookie
 * @param {string} key
 * @param {any} value
 * @param {number} expires
 * @param {boolean} noMax
 */
export const setCookie = (key, value, expires, noMax = false) => {
	if (typeof window !== 'undefined') {
		if (expires) {
			cookies.set(key, value, {
				path: '/',
				maxAge: !noMax ? expires : null,
				expires: noMax ? expires : null,
			});
		} else {
			cookies.set(key, value, {
				path: '/',
			});
		}
	}
};

/**
 * Remove Cookie
 * @param {string} key
 */
export const removeCookie = (key) => {
	if (typeof window !== 'undefined') cookies.remove(key, { path: '/' });
};

/**
 * Get Cookie
 * @param {string} key
 * @returns value of cookie
 */
export const getCookie = (key) => {
	if (typeof window !== 'undefined') return cookies.get(key);
};

/**
 * Provide Text Color
 * @param {*} hex - hex value
 * @returns text-white of text-black color class based on hex value starting character
 */
export const provideTextColor = (hex) => {
	if (/^[012345]/.test(hex?.split('#')?.[1])) return 'text-white';
	else return 'text-black';
};
