import Cookies from 'universal-cookie';
import { setCookie, getCookie, setSessionStorage } from '../../../utilities/functions';

const cookies = new Cookies();
let waitForCookie;
// Give 3 seconds for the cookie to be show. If the cookie is not accessible by then it probably isn't coming.
// 500ms each go
const maxInterval = 6;
let currentInterval = 0;
let urlstring = '';

export const generateUTMParams = (queryParams) => {
	const today = new Date();
	const expire = today.setDate(today.getDate() + 90);
	let hasPelCampaignCode = false;
	let pelCampaignCode = null;
	if (queryParams.get('pel_campaign_code')) {
		hasPelCampaignCode = true;
		pelCampaignCode = queryParams.get('pel_campaign_code');
		setSessionStorage('pel_campaign_code', queryParams.get('pel_campaign_code'), false);
		setCookie('pel_campaign_code', queryParams.get('pel_campaign_code'), new Date(expire), true);
	} else {
		const code = getCookie('pel_campaign_code');
		if (code) {
			hasPelCampaignCode = true;
			pelCampaignCode = code;
		}
	}

	waitForCookie = setInterval(() => intervalFunction(queryParams, hasPelCampaignCode, pelCampaignCode), 500);

	// utmcampaign
	if (queryParams.get('utm_campaign')) {
		if (urlstring !== '') urlstring += '^^';
		urlstring += `utmcampaign:${queryParams.get('utm_campaign')}`;
	} else if (queryParams.get('utmcampaign')) {
		if (urlstring !== '') urlstring += '^^';
		urlstring += `utmcampaign:${queryParams.get('utmcampaign')}`;
	} else if (queryParams.get('Campaign')) {
		if (urlstring !== '') urlstring += '^^';
		urlstring += `utmcampaign:${queryParams.get('Campaign')}`;
	}

	// utmsource
	if (queryParams.get('utm_source')) {
		if (urlstring !== '') urlstring += '^^';
		urlstring += `utmsource:${queryParams.get('utm_source')}`;
	} else if (queryParams.get('utmsource')) {
		if (urlstring !== '') urlstring += '^^';
		urlstring += `utmsource:${queryParams.get('utmsource')}`;
	}

	// utmmedium
	if (queryParams.get('utm_medium')) {
		if (urlstring !== '') urlstring += '^^';
		urlstring += `utmmedium:${queryParams.get('utm_medium')}`;
		console.log(urlstring);
	} else if (queryParams.get('utmmedium')) {
		if (urlstring !== '') urlstring += '^^';
		urlstring += `utmmedium:${queryParams.get('utmmedium')}`;
	}

	// utmterm
	if (queryParams.get('utm_term')) {
		if (urlstring !== '') urlstring += '^^';
		urlstring += `utmterm:${queryParams.get('utm_term')}`;
		console.log(urlstring);
	} else if (queryParams.get('utmterm')) {
		if (urlstring !== '') urlstring += '^^';
		urlstring += `utmterm:${queryParams.get('utmterm')}`;
	}

	// utmcontent
	let hasContent = false;
	if (queryParams.get('utm_content')) {
		if (urlstring !== '') urlstring += '^^';
		hasContent = true;
		urlstring += `utmcontent:${queryParams.get('utm_content')}`;
	} else if (queryParams.get('utmcontent')) {
		if (urlstring !== '') urlstring += '^^';
		hasContent = true;
		urlstring += `utmcontent:${queryParams.get('utmcontent')}`;
	}

	// gclid
	if (queryParams.get('gclid')) {
		if (urlstring !== '') urlstring += '^^';

		if (!hasContent) {
			urlstring += `utmgclid:${queryParams.get('gclid')}^^gclid:${queryParams.get('gclid')}`;
		} else {
			urlstring += `utmcontent:gclid-${queryParams.get('gclid')}^^utmgclid:${queryParams.get('gclid')}^^gclid:${queryParams.get('gclid')}`;
		}
	} else if (queryParams.get('utmgclid')) {
		if (urlstring !== '') urlstring += '^^';

		if (!hasContent) {
			urlstring += `utmgclid:${queryParams.get('utmgclid')}^^gclid:${queryParams.get('utmgclid')}`;
		} else {
			urlstring += `utmcontent:gclid-${queryParams.get('utmgclid')}^^utmgclid:${queryParams.get('utmgclid')}^^gclid:${queryParams.get('utmgclid')}`;
		}
	}

	// Network
	if (queryParams.get('Network')) {
		if (urlstring !== '') urlstring += '^^';
		urlstring += `Network:${queryParams.get('Network')}`;
	}

	// pel_campaign_code
	if (hasPelCampaignCode) {
		if (urlstring !== '') urlstring += '^^';
		urlstring += `pel_campaign_code:${pelCampaignCode}`;
	}

	// No urlstring thus far
	if (urlstring === '') urlstring = 'utmcampaign:(direct)^^utmsource:(direct)^^utmmedium:(none)^^rtapb:1';

	// Add parentSite
	let documentReferrer = null;
	let referredDomain = null;
	if (document !== undefined) {
		documentReferrer = document.referrer;
		if (documentReferrer != null) {
			const removeProtocol = documentReferrer.substring(documentReferrer.indexOf('//') + 2);
			referredDomain = removeProtocol.substring(0, removeProtocol.indexOf('/'));
		}
	}

	if (referredDomain === '') {
		urlstring += '^^parentSite:www.pellabranch.com';
	} else {
		urlstring += '^^parentSite:' + referredDomain;
	}

	if (cookies.get('ELOQUA')) {
		const eloquaId = cookies.get('ELOQUA');
		urlstring += `^^${eloquaId.replace('GUID=', 'EloquaID:').replace('&FPCVISITED:1', '')}`;
	}

	urlstring += `^^host:${window.location.host}`;

	setSessionStorage('urlstring', urlstring);
};

export const generateBranchUTMParams = (queryParams) => {
	return;
};

const createUTMZObjectFromString = (keyValueString) => {
	let utmzObject = {};
	keyValueString.forEach((keyValuePair) => {
		const temp = keyValuePair.split('=');
		const tempKey = temp[0];
		let tempValue = temp[1];

		if (tempValue != null) {
			tempValue = tempValue.replace('not provided', '');
			tempValue = tempValue.replace('not set', '');
		}

		switch (tempKey) {
			case 'utmccn':
				utmzObject['utmcampaign'] = tempValue;
				break;
			case 'utmcsr':
				utmzObject['utmsource'] = tempValue;
				break;
			case 'utmcmd':
				utmzObject['utmmedium'] = tempValue;
				break;
			case 'utmctr':
				utmzObject['utmterm'] = tempValue;
				break;
			case 'utmcct':
				utmzObject['utmcontent'] = tempValue;
				break;
			case 'utmgclid':
				utmzObject['utmgclid'] = tempValue;
				break;
			default:
				break;
		}
	});
	return utmzObject;
};

const intervalFunction = (queryParams, hasPelCampaignCode, pelCampaignCode) => {
	if (currentInterval < maxInterval) {
		// if __utmz cookie exists use values from it over anything else
		if (cookies.get('__utmz')) {
			urlstring = '';
			const utmzCookie = cookies.get('__utmz');
			const utmzCookiesInfo = utmzCookie.split('.');
			const splitUTMZCookieValue = (str) => {
				const keys = ['utmccn', 'utmcsr', 'utmcmd', 'utmctr', 'utmcct', 'utmgclid'];
				const keyValueArray = [];
				keys.forEach((key) => {
					const index = str.indexOf(key);
					if (index !== -1) {
						let start = index;
						let stop = false;
						let splitCharIndex = -1;
						while (!stop) {
							splitCharIndex = str.indexOf('|', start);
							if (splitCharIndex !== -1) {
								for (let i = 0; i < keys.length; i++) {
									if (str.substring(splitCharIndex + 1, splitCharIndex + 1 + keys[i].length) === keys[i]) {
										stop = true;
										break;
									}
								}
								start = str.indexOf('|', splitCharIndex + 1);
							} else {
								stop = true;
							}
						}
						if (splitCharIndex !== -1 && splitCharIndex > index) {
							keyValueArray.push(str.substring(index, splitCharIndex));
						} else {
							keyValueArray.push(str.substring(index));
						}
					}
				});
				return keyValueArray;
			};
			const utmzCookieValues = createUTMZObjectFromString(splitUTMZCookieValue(utmzCookiesInfo[4]));
			const keys = Object.keys(utmzCookieValues);
			keys.forEach((key) => {
				if (urlstring !== '') {
					urlstring += '^^';
				}
				urlstring += `${key}:${utmzCookieValues[key]}`;
			});

			// Network
			if (queryParams.get('Network')) {
				if (urlstring !== '') {
					urlstring += '^^';
				}
				urlstring += `Network:${queryParams.get('Network')}`;
			}
			// pel_campaign_code
			if (hasPelCampaignCode) {
				if (urlstring !== '') {
					urlstring += '^^';
				}
				urlstring += `pel_campaign_code:${pelCampaignCode}`;
			}
			// No urlstring thus far
			if (urlstring === '') {
				urlstring = 'utmcampaign:(direct)^^utmsource:(direct)^^utmmedium:(none)^^rtapb:1';
			}
			// Add parentSite
			let documentReferrer = null;
			let referredDomain = null;
			if (document !== undefined) {
				documentReferrer = document.referrer;
				if (documentReferrer != null) {
					const removeProtocol = documentReferrer.substring(documentReferrer.indexOf('//') + 2);
					referredDomain = removeProtocol.substring(0, removeProtocol.indexOf('/'));
				}
			}
			if (referredDomain === '') {
				urlstring += '^^parentSite:www.pellabranch.com';
			} else {
				urlstring += '^^parentSite:' + referredDomain;
			}
			// Add RTA_Source
			// if(useMediaQuery({ query: '(max-device-width: 789px)' })) {
			// 	urlstring += "^^RTA_Source:Mobile";
			// }
			// if (window !== undefined) {
			// 	if (window.innerWidth < 1030) {
			// 		urlstring += "^^RTA_Source:Mobile";
			// 	}
			// }
			if (cookies.get('ELOQUA')) {
				const eloquaId = cookies.get('ELOQUA');
				urlstring += `^^${eloquaId.replace('GUID=', 'EloquaID:').replace('&FPCVISITED:1', '')}`;
			}
			setSessionStorage('urlstring', urlstring);
			clearInterval(waitForCookie);
		} else {
			currentInterval++;
		}
	} else {
		clearInterval(waitForCookie);
	}
};
