import { combineReducers } from 'redux';
import errorReducer from './error-reducer';
import rtaDataReducer from './rta-reducer';
import rtaLogReducer from './rta-log-reducer';
import qasReducer from './qas-reducer';
export default combineReducers({
	error: errorReducer,
	rtaData: rtaDataReducer,
	rtaLogData: rtaLogReducer,
	qasData: qasReducer,
});
