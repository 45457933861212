import { graphql, useStaticQuery } from 'gatsby';

export const useBranchData = () => {
	const data = useStaticQuery(graphql`
		query {
			allContentstackBranchData {
				nodes {
					branch_retail
					branch_trade
					formal_name
					branch_url
					phone_number
					has_financing
					has_blogs
					has_projects
					hide_rta
					has_pay_now
					custom_disclaimer
					socials {
						...CTA
					}
					utm_branch
				}
			}
		}
	`);

	return data.allContentstackBranchData.nodes;
};
