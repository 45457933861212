export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const INSERT_PARTIAL_LEAD = 'INSERT_PARTIAL_LEAD';
export const NEXT_ID = 'NEXT_ID';
export const GET_CALENDAR_INFO = 'GET_CALENDAR_INFO';
export const APPOINTMENT = 'APPOINTMENT';
export const CALL_CENTER_APPOINTMENT = 'CALL_CENTER_APPOINTMENT';
export const TIMEBLOCK = 'TIMEBLOCK';
export const CONVERT_LEAD = 'CONVERT_LEAD';
export const STORE_APPOINTMENT = 'STORE_APPOINTMENT';
export const STORE_LEAD_CONVERT = 'STORE_LEAD_CONVERT';
export const STORE_APPOINTMENT_CHANGE = 'STORE_APPOINTMENT_CHANGE';
export const SUBMIT_APPOINTMENT_ERROR = 'SUBMIT_APPOINTMENT_ERROR';
export const SUBMIT_APPOINTMENT_CHANGE_ERROR = 'SUBMIT_APPOINTMENT_CHANGE_ERROR';
export const RESET_RTA_DATA = 'RESET_RTA_DATA';
export const RTA_LOG = 'RTA_LOG';

export const QAS_SEARCH = 'QAS_SEARCH';
export const QAS_FORMAT = 'QAS_FORMAT';
export const QAS_RESET = 'QAS_RESET';
