import React from 'react';
import { Provider } from 'react-redux';
import { BranchProvider } from './src/context/branch-context';
import { SessionWrapper } from './src/components/common/session-wrapper';

import store from './src/components/store';
import { Speedcurve } from './src/components/third-party/speedcurve';
import { GoogleTagManager } from './src/components/third-party/google-tagmanager';

// eslint-disable-next-line react/display-name, react/prop-types -- gatsby component
export default ({ element }) => (
	<Provider store={store}>
		<BranchProvider>
			<SessionWrapper>
				{element}
				{process.env.NODE_ENV === 'production' && (
					<>
						<Speedcurve />
						<GoogleTagManager />
					</>
				)}
			</SessionWrapper>
		</BranchProvider>
	</Provider>
);
