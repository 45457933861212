import { graphql, useStaticQuery } from 'gatsby';

export const useShowroomUrls = () => {
	const data = useStaticQuery(graphql`
		query {
			allContentstackShowroom {
				nodes {
					url
					showroom_id
					formal_name
					branch_data {
						formal_name
						branch_retail
						branch_trade
					}
				}
			}
		}
	`);

	return data.allContentstackShowroom.nodes;
};
