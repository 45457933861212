export const US_ZIP_REGEX = /^(\d{5}(\-\d{4})?)$/;
export const CA_ZIP_REGEX = /^\D\d\D(\-| |)?\d\D\d$/i;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REPLACE = /\(|\)|\-|\_| /g;

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const RTA_COMMON = {
	INTERFACE: {
		WEB: 'Web',
		SHOWROOM: 'Showroom',
		CALL_CENTER: 'Call Center',
	},
	ZONE: {
		RT_ZONE: 'RT',
		NRT_ZONE: 'NRT',
	},
	TYPE: {
		HOME: 'home',
		VIRTUAL: 'virtual',
		PREFERENCE: 'preference',
		NRT: 'nrt',
		SHOWROOM: 'showroom',
	},
	BLOCK_TYPE: {
		BLOCK: 'Block',
		UNBLOCK: 'Unblock',
		UNBLOCK_BLOCK: 'Both',
	},
};

// RTA Errors
export const RTA_ERRORS = {
	RESOURCE_UNAVAILABLE: 'RESOURCE NOT AVAILABLE:',
	INVALID_POSTAL: 'Postal Code must be a valid Postal Code',
	INVALID_EMAIL: 'Email Address is not a valid format',
	INVALID_PHONE: 'Phone is not a valid phone number',
	POSTAL_REQUIRED: 'Postal Code is a required field',
	FIRSTNAME_REQUIRED: 'First Name is a required field',
	LASTNAME_REQUIRED: 'Last Name is a required field',
	EMAIL_REQUIRED: 'Email Address is a required field',
	PHONE_REQUIRED: 'Phone is a required field',
	PHONE_TYPE_REQUIRED: 'Phone Type is a required field',
	PRIVACY_REQUIRED: 'You must accept our Terms of Use and Privacy Policy to proceed',
	PREFERRED_DAY_REQUIRED: 'Preferred Day is a required field',
	PREFERRED_TIME_REQUIRED: 'Preferred Time is a required field',
	APPOINTMENT_TYPE_REQUIRED: 'Appointment Type is a required field',
	DATE_TIME_REQUIRED: 'Date and time is required',
	ADDRESS_VALIDATION_REQUIRED: 'Project Address must be validated',
	ADDRESS_LINE_1_REQUIRED: '',
	CITY_REQUIRED: '',
	STATE_REQUIRED: '',

	POSTAL_ERROR_HANDLER: `You must fill out <a href='#postalCode'>Postal Code</a>`,
	FIRSTNAME_ERROR_HANDLER: `You must fill out <a href='#firstname'>First Name</a>`,
	LASTNAME_ERROR_HANDLER: `You must fill out <a href='#lastname'>Last Name</a>`,
	EMAIL_ERROR_HANDLER: `You must provide a valid <a href='#email'>Email Address</a>`,
	PHONE_ERROR_HANDLER: `You must provide a <a href='#phone'>Phone Number</a>`,
	PHONE_TYPE_ERROR_HANDLER: `You must choose a <a href='#phonetype'>Phone Type</a>`,
	PRIVACY_ERROR_HANDLER: `You must accept our <a href='#privacyCheck'>Terms of Use and Privacy Policy</a> to proceed`,
	ADDRESS_VALIDATION_ERROR_HANDLER: 'Project Address must be validated',
	ADDRESS_LINE_1_ERROR_HANDLER: `You must fill out <a href='#address1'>Street Address</a>`,
	CITY_ERROR_HANDLER: `You must fill out <a href='#city'>City</a>`,
	STATE_ERROR_HANDLER: `You must fill out <a href='#state'>State</a>`,
	DATE_TIME_ERROR_HANDLER: `You must select a <a href='#js-Calendar'>Date and Time</a>`,
	PREFERRED_DAY_ERROR_HANDLER: `You must select a <a href='#preferenceDay'>Preferred Day</a>`,
	PREFERRED_TIME_ERROR_HANDLER: `You must select a <a href='#preferenceTime'>Preferred Time</a>`,
	APPOINTMENT_TYPE_ERROR_HANDLER: `You must choose an <a href='#appointmentType'>Appointment Type</a>`,
};
